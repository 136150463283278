$(document).ready(function () {

  // Dropdown Menu Desktop
  var menuitem = $('.c-header__mainmenu').find('ul.sub-menu li.menu-item-has-children').find('.arrow');
  $(menuitem).click(function () {
    $(this).parent().toggleClass('is-active');
  });

  /* ********************* */
  /* Mobile Menu                */
  /* ********************* */

  // Menu mobile opening
  var menutoggle = $('.js-navmobile__toggle');
  $(menutoggle).click(function () {
    $('.c-navmobile__container').toggleClass('is-open');
    $('.c-stickywrapper').toggleClass('c-header--fixed');
    $(this).toggleClass('is-opened');
  });
  // Dropdown Menu Mobile
  var menuitem = $('.c-navmobile__mainmenu li.menu-item-has-children .arrow');
  $( menuitem ).click(function() {
    $(this).parent().toggleClass('is-active');
    //console.log('click');
  });

  /* ********************* */
  /* BOX SERVIZI                */
  /* ********************* */
  var boxservizi = $('.c-boxservizi--closing .c-boxservizi__top');
  $( boxservizi ).click(function() {
    $(this).parent().toggleClass('is-open');
    //console.log('click');
  });


    /* ********************* */
  /* Filtri Blog                */
  /* ********************* */
  
  $('.c-blog__mfbutton').click(function () {
    if ($(this).parent().hasClass("is-open")) {
      $(this).parent().removeClass('is-open');
      $(this).parent().addClass('is-closed');
    } else {
      $(this).parent().removeClass('is-closed');
      $(this).parent().addClass('is-open');
    }
  });


  /* ********************* */
  /* SLIDER                */
  /* ********************* */
  if ($(".splide").length > 0) {
    
    var splide = new Splide('.splide', {
      type: 'loop',
      perPage: 2,
      perMove: 1,
      gap: '16px',
      focus: 0,
      arrows: false,
      drag: 'free',
      snap: true,
      breakpoints: {
        960: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        },
      }
    });

    splide.mount();

  }




  /* ********************* */
  /* COUNTER ANIMATION                */
  /* ********************* */

  $('.c-numbers__list').waypoint({
    handler: function() {
      $('.count').each(function() {
        const initial = $(this).text()
        const format = formatter(initial)
        $(this).prop('Counter', 0).animate({
          Counter: format.value
        }, {
          duration: 2000,
          easing: 'swing',
          step: function(now) {
            $(this).text(format.revert(Math.ceil(now)));
          }
        });
      });
      this.destroy();
    },
    offset: '100%'
  });


function formatter(str) {
  // const delimeter = '-'
  const char = 'x'
  const template = str.replace(/\d/g, char)
  const value = str.replace(/\D/g, '')

  function revert(val) {
    // need better solution
    const valStr = val.toString()
    let result = ''
    let index = 0
    for (let i = 0; i < template.length; i++) {
      const holder = template[i]
      if (holder === char) {
        result += valStr.slice(index, index + 1)
        index++
      } else {
        result += holder
      }
    }
    return result
  }
  return {
    template: template,
    value: value,
    revert: revert
  }
}
 



  
});
